<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab lazy>
        <template #title>
          <feather-icon icon="SendIcon" />
          Yeni Gönderim
        </template>
        <new-survey />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ListIcon" />
          Gönderilenler
        </template>
        <survey-list />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import NewSurvey from '@/views/Surveys/Components/newSurvey.vue'
import SurveyList from '@/views/Surveys/Components/SurveyList.vue'

export default {
  name: 'Index',
  components: {
    SurveyList,
    NewSurvey,
    BTabs,
    BTab,
  },
}
</script>
