<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-card title="Gönderilecek Numaralar">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1"
            >
              <v-select
                v-model="id_com_survey_type"
                :options="surveyTypes"
                label="title"
                :reduce="item => item.id"
                placeholder="Anket Türü Seçiniz"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mb-1"
            >
              <b-button
                variant="danger"
                class="btn-block"
                href="https://inll.app/Medusa2/media/surveys/anket_aktarim_sablonu.xlsx"
                target="blank"
              >
                <feather-icon icon="DownloadIcon" />
                Şablon Dosyası
              </b-button>
            </b-col>
            <b-col>
              <b-form-textarea
                id="phones"
                v-model="phones"
                rows="20"
                placeholder="Telefon Listesi"
                :disabled="!id_com_survey_type"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mt-1"
            >
              <b-button
                variant="primary"
                class="btn-block"
                :disabled="!id_com_survey_type || customerPhones.length < 1 || loadingData"
                @click="sendBatch"
              >
                <b-spinner
                  v-if="loadingData"
                  small
                  class="mr-1"
                />
                <feather-icon icon="SendIcon" />
                Gönder
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <b-card title="Gönderim Durumu">
          <b-alert
            v-if="customerPhones.length < 1"
            show
            variant="warning"
          >
            <div class="alert-body text-center">
              <div>
                <span>
                  <feather-icon icon="InfoIcon" />
                </span>
              </div>
              <div>
                Gönderim listesinin oluşması için yan taraftaki "Şablon Dosyası" butonuna tıkladığınızda inecek olan excel tablosunu doldurduktan sonra tabloyu başlıkları ile birlikte yandaki kutucuğa kopyalamanız yeterlidir.
              </div>
            </div>
          </b-alert>
          <b-row v-else>
            <b-col

              cols="12"
              md="12"
            >
              <b-table-simple
                small
                bordered
              >
                <b-thead>
                  <b-tr>
                    <b-th>Telefon No.</b-th>
                    <b-th>Danışman Adı</b-th>
                    <b-th>Araç Plaka / Kodu</b-th>
                    <b-th>Araç Markası</b-th>
                    <b-th>Araç Modeli</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-for="(row,key) in customerPhones">
                    <template v-if="row[0]">
                      <b-tr
                        :key="key"
                      >
                        <b-td>
                          {{ row[0] }}
                        </b-td>
                        <b-td>
                          {{ row[1] }}
                        </b-td>
                        <b-td>
                          {{ row[2] }}
                        </b-td>
                        <b-td>
                          {{ row[3] }}
                        </b-td>
                        <b-td>
                          {{ row[4] }}
                        </b-td>
                      </b-tr>
                    </template>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormTextarea, BAlert, BButton, BSpinner, BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'NewSurvey',
  components: {
    BAlert,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    vSelect,
    BButton,
    BSpinner,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
  },
  data() {
    return {
      phones: null,
      id_com_survey_type: null,
    }
  },
  computed: {
    customerPhones() {
      return this.$store.getters['surveys/customerPhones']
    },
    surveyTypes() {
      return this.$store.getters['surveyType/getDataList']
    },
    loadingData() {
      return this.$store.getters['surveys/dataLoading']
    },
  },
  watch: {
    phones(val) {
      if (val) {
        // Satırları \n ile ayır ve her bir satırı , ile bölerek diziye çevir
        const phonesArray = val.split('\n').map(line => line.split('\t'))
        phonesArray.splice(0, 1)
        this.$store.commit('surveys/SET_CUSTOMER_PHONES', phonesArray)
      } else {
        this.$store.commit('surveys/RESET_CUSTOMER_PHONES')
      }
    },
  },
  created() {
    this.getSurveyTypes()
  },
  methods: {
    getSurveyTypes() {
      this.$store.dispatch('surveyType/dataList', {
        select: [
          'com_survey_type.id AS id',
          'com_survey_type.title AS title',
        ],
        where: {
          'com_survey_type.id !=': 1, // burası 2.el anketlerini pas geçmeli. telefon numarası ile direkt anket yapılamamakta
        },
      })
    },
    sendBatch() {
      this.$swal({
        title: 'Toplu Anket Smsi Gönderimi',
        text: 'Listeye eklenilen telefon numaralarına ait müşterilere anket smsi gönderilecek onaylıyor musunuz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('surveys/sendBatchSurvey', { id_com_survey_type: this.id_com_survey_type, customer_phones: this.customerPhones })
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Toplu Anket Sms Gönderimi',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$store.commit('surveys/RESET_CUSTOMER_PHONES')
                this.phones = null
              } else {
                this.$swal({
                  icon: 'danger',
                  title: 'Toplu Anket Sms Gönderimi',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
