<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col>
            <b-form-input
              v-model="filterData.keyword"
              placeholder="Müşteri Ad,telefon numarası ile ara.."
              @keydown.enter="searchData"
            />
          </b-col>
          <b-col>
            <v-select
              id="id_com_survey_type"
              v-model="filterData.id_com_survey_type"
              label="title"
              :reduce="item => item.id"
              :options="surveyTypes"
              placeholder="Anket Tipi"
              @input="searchData"
            />
          </b-col>
          <b-col>
            <v-select
              id="id_com_survey_status"
              v-model="filterData.id_com_survey_status"
              label="title"
              :reduce="item => item.id"
              :options="surveyStatuses"
              placeholder="Anket Durumu"
              @input="searchData"
            />
          </b-col>
          <b-col md="auto">
            <b-button
              :disabled="dataLoading"
              variant="primary"
              @click="searchData"
            >
              <b-spinner
                v-if="dataLoading"
                small
              />
              <feather-icon
                v-else
                icon="FilterIcon"
              />
              Filtrele
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(customer)="data">
          <div v-if="data.item.survey_code">
            <b-badge variant="light-warning">
              <feather-icon icon="ListIcon" />
              {{ data.item.survey_code }}
            </b-badge>
          </div>
          <template v-if="data.item.id_com_customer">
            {{ data.item.company_name ? data.item.company_name : data.item.customer ? data.item.customer : 'Müşteri Kartında Eksik Bilgi' }}
          </template>
          <template v-else>
            Müşteri Kartı Bulunamadı
          </template>
          <div class="mt-1">
            <b-badge
              v-if="data.item.survey_type"
              variant="light-primary"
            >
              <feather-icon icon="FolderIcon" />
              {{ data.item.survey_type }}
            </b-badge>
            <b-badge
              v-if="data.item.phone"
              variant="light-info"
              class="ml-1"
            >
              <feather-icon icon="PhoneIcon" />
              {{ data.item.phone }}
            </b-badge>
          </div>
        </template>
        <template #cell(survey_status)="data">
          <b-badge
            v-if="data.item.id_com_survey_status === '1'"
            variant="light-warning"
          >
            <feather-icon icon="ClockIcon" />
            {{ data.item.survey_status }}
          </b-badge>
          <b-badge
            v-if="data.item.id_com_survey_status === '2'"
            variant="light-success"
          >
            <feather-icon icon="CheckIcon" />
            {{ data.item.survey_status }}
          </b-badge>
          <b-badge
            v-if="data.item.id_com_survey_status === '3'"
            variant="light-danger"
          >
            <feather-icon icon="XIcon" />
            {{ data.item.survey_status }}
          </b-badge>
          <b-badge
            v-if="data.item.id_com_survey_status === '4'"
            variant="light-warning"
          >
            <feather-icon icon="AlertCircleIcon" />
            {{ data.item.survey_status }}
          </b-badge>
          <b-badge
            v-if="data.item.id_com_survey_status === '5'"
            variant="light-danger"
          >
            <feather-icon icon="XIcon" />
            {{ data.item.survey_status }}
          </b-badge>
          <div class="mt-1">
            <b-badge
              v-if="data.item.id_com_survey_status === '1' || data.item.id_com_survey_status === '3'"
              variant="light-secondary"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="font-weight-bolder"> Gönderilme: </span>{{ moment(data.item.created).format('DD.MM.YYYY') }}
            </b-badge>
            <b-badge
              v-if="data.item.id_com_survey_status === '2'"
              variant="light-secondary"
            >
              <feather-icon icon="CheckIcon" />
              <span class="font-weight-bolder"> Cevaplanma: </span>{{ moment(data.item.close_date).format('DD.MM.YYYY') }}
            </b-badge>

          </div>
        </template>
        <template #cell(control)="data">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="showDetailModal(data.index)"
          >
            <feather-icon icon="SearchIcon" />
            Detay
          </b-button>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
    <b-modal
      v-model="detailModal.status"
      title="Anket Detayı"
      centered
      size="xl"
      hide-footer
    >
      <b-row v-if="detailModal.index !== null">
        <b-col
          cols="12"
          md="4"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Numarası
              </div>
              <div>
                <b-badge variant="light-warning">
                  <feather-icon icon="ListIcon" />
                  {{ dataList[detailModal.index].survey_code }}
                </b-badge>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Müşteri Adı / Firma Adı
              </div>
              <div>
                <b-badge
                  v-if="dataList[detailModal.index].id_com_customer"
                  variant="light-secondary"
                >
                  <feather-icon icon="UsersIcon" />
                  {{ dataList[detailModal.index].company_name ? dataList[detailModal.index].company_name : dataList[detailModal.index].customer ? dataList[detailModal.index].customer : 'Müşteri kartında eksik bilgi.' }}
                </b-badge>
                <b-badge
                  v-else
                  variant="light-danger"
                >
                  <feather-icon icon="UsersIcon" />
                  Müşteri Kartı Bulunamadı.
                </b-badge>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Telefon Numarası
              </div>
              <div>
                <b-badge
                  variant="light-info"
                >
                  <feather-icon icon="PhoneIcon" />
                  {{ dataList[detailModal.index].customer_phone ? dataList[detailModal.index].customer_phone : 'Müşteri kartında eksik bilgi.' }}
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Tipi
              </div>
              <div>
                <b-badge variant="light-primary">
                  <feather-icon icon="FolderIcon" />
                  {{ dataList[detailModal.index].survey_type }}
                </b-badge>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Telefon Numarası
              </div>
              <div>
                <b-badge
                  variant="light-info"
                >
                  <feather-icon icon="PhoneIcon" />
                  {{ dataList[detailModal.index].phone ? dataList[detailModal.index].phone : '-' }}
                </b-badge>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Oluşturulma Tarihi
              </div>
              <div>
                <b-badge
                  variant="light-success"
                >
                  <feather-icon icon="CalendarIcon" />
                  {{ dataList[detailModal.index].created ? moment(dataList[detailModal.index].created).format('DD.MM.YYYY') : '-' }}
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Durumu
              </div>
              <div>
                <b-badge
                  v-if="dataList[detailModal.index].id_com_survey_status === '1'"
                  variant="light-warning"
                >
                  <feather-icon icon="ClockIcon" />
                  {{ dataList[detailModal.index].survey_status }}
                </b-badge>
                <b-badge
                  v-if="dataList[detailModal.index].id_com_survey_status === '2'"
                  variant="light-success"
                >
                  <feather-icon icon="CheckIcon" />
                  {{ dataList[detailModal.index].survey_status }}
                </b-badge>
                <b-badge
                  v-if="dataList[detailModal.index].id_com_survey_status === '3'"
                  variant="light-danger"
                >
                  <feather-icon icon="XIcon" />
                  {{ dataList[detailModal.index].survey_status }}
                </b-badge>
                <b-badge
                  v-if="dataList[detailModal.index].id_com_survey_status === '4'"
                  variant="light-warning"
                >
                  <feather-icon icon="AlertCircleIcon" />
                  {{ dataList[detailModal.index].survey_status }}
                </b-badge>
                <b-badge
                  v-if="dataList[detailModal.index].id_com_survey_status === '5'"
                  variant="light-danger"
                >
                  <feather-icon icon="XIcon" />
                  {{ dataList[detailModal.index].survey_status }}
                </b-badge>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Erişimi
              </div>
              <div>
                <b-badge
                  v-if="dataList[detailModal.index].status !== '1'"
                  variant="light-info"
                >
                  <feather-icon icon="ClockIcon" />
                  Açık
                </b-badge>
                <b-badge
                  v-if="dataList[detailModal.index].status === '1'"
                  variant="light-dark"
                >
                  <feather-icon icon="CheckIcon" />
                  Kapalı
                </b-badge>
              </div>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Cevaplanma Tarihi
              </div>
              <div>
                <b-badge
                  variant="light-danger"
                >
                  <feather-icon icon="CalendarIcon" />
                  {{ dataList[detailModal.index].close_date ? moment(dataList[detailModal.index].close_date).format('DD.MM.YYYY') : 'Henüz Cevaplanmadı' }}
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col
          v-if="dataList[detailModal.index].interview_number"
          cols="12"
          md="12"
          class="mt-2"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Görüşme Notu
              </div>
              <div>
                <b-link
                  class="font-weight-bolder text-info"
                  :to="'/interviews/view/'+dataList[detailModal.index].interview_number"
                  target="_blank"
                >
                  <feather-icon icon="LinkIcon" />
                  {{ dataList[detailModal.index].interview_number }}
                </b-link>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mt-2"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Anket Geri Bildirim Notu
              </div>
              <div>
                {{ dataList[detailModal.index].customer_request ? dataList[detailModal.index].customer_request : 'Anket notu oluşturulmamış.' }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BCardFooter, BBadge, BButton, BModal, BRow, BCol, BListGroupItem, BListGroup, BFormInput, BSpinner, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

const tableName = 'com_surveys'
export default {
  name: 'SurveyList',
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BCardFooter,
    BBadge,
    BButton,
    BModal,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    BSpinner,
    BLink,
  },
  data() {
    return {
      detailModal: {
        status: false,
        index: null,
      },
      searching: false,
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'customer',
          label: 'Anket',
        },
        {
          key: 'survey_status',
          label: 'Durum',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id      AS id`,
          `${tableName}.survey_code AS survey_code`,
          `${tableName}.status     AS status`,
          `${tableName}.close_date AS close_date`,
          `${tableName}.phone      AS phone`,
          `${tableName}.customer_request      AS customer_request`,
          `${tableName}.id_com_customer      AS id_com_customer`,
          `${tableName}.id_com_survey_status      AS id_com_survey_status`,
          `${tableName}.interview_number      AS interview_number`,
          `${tableName}.created      AS created`,
          'com_survey_type.title AS survey_type',
          'com_survey_status.title AS survey_status',
          'com_customer.company_name AS company_name',
          'com_customer.name AS customer',
          'com_customer.phone AS customer_phone',
        ],
        where: {},
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['surveys/dataList']
    },
    dataCounts() {
      return this.$store.getters['surveys/dataCounts']
    },
    saveData() {
      return this.$store.getters['surveys/dataSaveStatus']
    },
    filterData() {
      return this.$store.getters['surveys/filterData']
    },
    surveyTypes() {
      return this.$store.getters['surveyType/getDataList']
    },
    surveyStatuses() {
      return this.$store.getters['surveyStatus/getDataList']
    },
    dataLoading() {
      return this.$store.getters['surveys/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getSurveyTypes()
    this.getSurveyStatuses()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('surveys/getDataList', this.dataQuery)
    },
    searchData() {
      this.dataQuery.where = {}
      this.dataQuery.or_like = {}
      if (this.filterData.id_com_survey_type) {
        this.dataQuery.where['com_surveys.id_com_survey_type'] = this.filterData.id_com_survey_type
      }
      if (this.filterData.id_com_survey_status) {
        this.dataQuery.where['com_surveys.id_com_survey_status'] = this.filterData.id_com_survey_status
      }
      if (this.filterData.keyword) {
        this.dataQuery.or_like = {
          'com_customer.name': this.filterData.keyword,
          'com_customer.company_name': this.filterData.keyword,
          'com_customer.phone': this.filterData.keyword,
          'com_surveys.phone': this.filterData.keyword,
          'com_surveys.survey_code': this.filterData.keyword,
        }
      }
      this.getDataList()
    },
    getSurveyTypes() {
      this.$store.dispatch('surveyType/dataList', {
        select: [
          'com_survey_type.id AS id',
          'com_survey_type.title AS title',
        ],
      })
    },
    getSurveyStatuses() {
      this.$store.dispatch('surveyStatus/dataList', {
        select: [
          'com_survey_status.id AS id',
          'com_survey_status.title AS title',
        ],
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
    showDetailModal(index) {
      this.detailModal.index = index
      this.detailModal.status = true
    },
  },
}
</script>
